import React, { useState, useEffect } from 'react';
import { PopupWidget } from 'react-calendly';
import '../styles/Navbar.css';
import Ctllogo from '../assets/ctl_logo1-removebg-preview.png';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showCalendlyPopup, setShowCalendlyPopup] = useState(false);
  const [isPopupVibrating, setIsPopupVibrating] = useState(false);

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCalendlyPopup = () => {
    if (showCalendlyPopup) {
      // Trigger vibration if popup is already open
      setIsPopupVibrating(true);
      setTimeout(() => setIsPopupVibrating(false), 500); // Reset after vibration ends
    } else {
      // Open the popup normally
      setShowCalendlyPopup(true);
    }
  };

  // Effect to handle screen resizing and reset the menu
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false); 
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className="navbar">
      {/* Hamburger icon for mobile */}
      <div className={`hamburger ${isMobileMenuOpen ? 'active' : ''}`} onClick={handleMenuToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      {/* Logo or brand name */}
      <div className="logo">
        <img src={Ctllogo} alt="Logo" />
      </div>
      {/* Navigation links */}
      <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#coaching">Coaching</a></li>
        <li><a href="#contact">Contact</a></li>
        <li>
          <button
            className="book-button"
            onClick={handleCalendlyPopup}
          >
            Book Now
          </button>
        </li>
      </ul>

      {/* Calendly Popup Widget */}
      {showCalendlyPopup && (
        <div className={`popup-container ${isPopupVibrating ? 'vibrate' : ''}`}>
          <PopupWidget
            url="https://calendly.com/kzubricky"
            rootElement={document.getElementById('root')}
            text="Schedule time with me"
            textColor="#ffffff"
            color="#00a2ff"
            onClose={() => setShowCalendlyPopup(false)}
          />
        </div>
      )}
    </nav>
  );
}

export default Navbar;
