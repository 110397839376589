import React from "react";
import "../styles/Footer.css";

const FooterComponent = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h2>Cleveland Tennis Lessons</h2>
        </div>

        {/* Updated Footer Navigation with animation */}
        <nav className="footer-nav">
          <ul className="footer-menu">
            <li className="footer-menu-item">
              <a href="#home" className="footer-link">Home</a>
            </li>
            <li className="footer-menu-item">
              <a href="#about" className="footer-link">About</a>
            </li>
            <li className="footer-menu-item">
              <a href="#coaching" className="footer-link">Coaching</a>
            </li>
            <li className="footer-menu-item">
              <a href="#contact" className="footer-link">Contact</a>
            </li>
          </ul>
        </nav>

        {/* Social Media Links */}
        <div className="footer-social">
          <a href="https://www.facebook.com/kyand23" target="_blank" rel="noreferrer" className="social-icon facebook-icon"></a>
          <a href="https://www.instagram.com/kyzubricky/" target="_blank" rel="noreferrer" className="social-icon instagram-icon"></a>
          <a href="https://x.com/Kyzuby" target="_blank" rel="noreferrer" className="social-icon twitter-icon"></a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© All rights reserved.</p>
      </div>
    </footer>
  );
};

export default FooterComponent;
