import React from 'react';
import '../styles/Timeline.css'; 

const Timeline = () => {
  return (
    <div className="timeline-section">
      <div className="timeline-header">
        <h2>Timeline</h2>
        <h3>Kyle Zubricky's Tennis Journey</h3>
      </div>
      <div className="timeline">
        {/* Timeline Container 1 */}
        <div className="timeline-container right-container">
          <div className="timeline-circle"></div>
          <div className="timeline-body">
            <h2>Started Coaching Career</h2>
            <small>2010</small>
            <p>Kyle began his journey in tennis, playing at the local club.</p>
            <span className="right-container-arrow"></span>
          </div>
        </div>

        {/* Timeline Container 2 */}
        <div className="timeline-container left-container">
          <div className="timeline-circle"></div>
          <div className="timeline-body">
            <h2>First National Championship</h2>
            <small>2013</small>
            <p>Participated in first Nation Championship.</p>
            <span className="left-container-arrow"></span>
          </div>
        </div>

        {/* Timeline Container 3 */}
        <div className="timeline-container right-container">
          <div className="timeline-circle"></div>
          <div className="timeline-body">
            <h2>Founded Elite Tennis Academy</h2>
            <small>2017</small>
            <p>Joined a Tennis Academy, horning his skill.</p>
            <span className="right-container-arrow"></span>
          </div>
        </div>

        {/* Timeline Container 4 */}
        <div className="timeline-container left-container">
          <div className="timeline-circle"></div>
          <div className="timeline-body">
            <h2>Reached 10,000 Coaching Hours</h2>
            <small>2022</small>
            <p>Achieved a milestone of 10,000 hours of playing, making him a seasoned expert.</p>
            <span className="left-container-arrow"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
