import React from "react";
import "../styles/ContactSection.css";

const ContactSection = () => {
  return (
    <section className="contact-section">
      <div className="contact-background">
        <div className="contact-overlay">
          <h2>Get in Touch</h2>
          <p>I am here to help you elevate your tennis game. Reach out to me for any queries or to schedule a session.</p>
          
          {/* Contact Information */}
          <div className="contact-info">
            <p><strong>Email:</strong> kzubricky@gmail.com </p>
            <p><strong>Phone:</strong> +1 (440) 915-2518</p>
            <p><strong>Location:</strong>Flexible</p>
            <p><strong>Office Hours:</strong>Flexible</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

