// HomeSection.jsx
import React from 'react';
import { Link } from 'react-scroll';
import '../styles/HomeSection.css';
import Background from '../assets/homepage_background-removebg-preview.png';

function HomeSection() {
  return (
    <section className="home-section" id="home">
      <div className="home-content">
        <h1 className="typing-animation">
          Unleash Your Tennis <br /> 
          Potential: <br />
          Expert Coaching
        </h1>
        <p>Discover the joy of tennis with my personalized coaching approach. Whether you're a seasoned player or a beginner.</p>
        {/* Scrolls to the Enrollment Form */}
        <Link to="enroll" smooth={true} duration={500} className="enroll-button">Enroll Now</Link>
      </div>
      <div className="home-image">
        <img src={Background} alt="Tennis Player" />
      </div>
    </section>
  );
}

export default HomeSection;
