import React from 'react';
import '../styles/AboutSection.css';
import InstructorImage from '../assets/kyle1.jpg';
import Timeline from './Timeline';
import UTRlink from '../assets/utr_alternative.png';

const AboutSection = () => {
  return (
    <section className="about-section">
      {/* About Wrapper and Timeline side by side */}
      <div className="about-wrapper">
        <div className="about-content">
          <div className="about-left">
            <img src={InstructorImage} alt="Kyle Zubricky" className="instructor-image" />
          </div>
          <div className="about-right">
            <div className="about-text">
              <h2>Owner & Instructor</h2>
              <h3>Kyle Zubricky</h3>
              <p>
                Over ten years of experience playing tennis. I graduated college in 2022 with a degree in marketing. I currently live in Rocky River. I do teaching in Westlake, Lakewood, Independence, and in Solon. Last Year in 2023, I played in the NTRP Nationals tournament in San Diego after winning the NTRP Sectional Midwest Championship.
              </p>
            </div>
            <div className="stats-container">
              <h4>Verified Singles Ranking (Male)</h4>
              <div className="stats-grid">
                <div className="stat-item">
                  <span>#16,925</span>
                  <p>United States <em>Adult</em></p>
                </div>
                <div className="stat-item">
                  <span>#29,782</span>
                  <p>United States</p>
                </div>
                <div className="stat-item">
                  <span>#93,362</span>
                  <p>World <em>Adult</em></p>
                </div>
                <div className="stat-item">
                  <span>#137,537</span>
                  <p>World</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="images-container">
          <div className="utr-link-image">
            <h4>UTR Sports Stats</h4>
            <a href="https://app.utrsports.net/profiles/311638?t=4">
              <img src={UTRlink} alt="UTR Stats" />
            </a>
          </div>
        </div>
      </div>

      {/* Timeline aligned next to the about-wrapper */}
      <div className="timeline-wrapper">
        <Timeline />
      </div>
    </section>
  );
};

export default AboutSection;


